.tab-content {
  padding-top: 80px;
  padding-bottom: 70px;

  &__p0 {
    padding: 0!important;
  }

  @media screen and (max-width: $sm-point){
    padding-top: 15px;
    padding-bottom: 60px;
  }
}

.authors-filter__alpha {

  @media screen and (max-width: $md-point){
    .tab {
      &-item {
        font-size: 30px!important;
      }
    }
  }

  @media screen and (max-width: $sm-point){
    flex-wrap: nowrap!important;
    overflow-x: scroll;
  }

}

.tab-title {
  opacity: 0;
  height: 0;
  visibility: hidden;
  transition: opacity .3s ease-in;
  &__active {
    opacity: 1;
    height: auto;
    visibility: visible;
  }
}

.tabs-container {
  padding-top: 54px;
  justify-content: space-between;

  .tab {
    display: flex;
    cursor: pointer;

    &:hover {
      .tab-item, .tab-item__counter {
        color: $red;
      }
    }

    &-item {
      color: rgba(3, 19, 14, 0.75);
      text-transform: capitalize;
      transition: all .3s ease-in-out;
      font-weight: 600;
      font-size: 30px;
      line-height: 70px;
      @media screen and (max-width: $md-point){
        font-size: 25px !important;
      }

      &__counter {
        line-height: 70px;
        font-size: 30px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        margin-top: -14px;
        color: rgba(3, 19, 14, 0.75);
        margin-left: 10px;
        transition: all .3s ease-in-out;
      }

      &__counter-big {
        font-size: 40px;
        line-height: 60px;
      }
    }

    &__active {
      .tab-item, .tab-item__counter {
        color: $blue;
      }
    }
  }

  @media screen and (max-width: $md-point){
    padding-top: 60px;
    .tab {

      .main-title {
        font-size: 70px;
        line-height: 75px;
      }

      &-item {
        font-size: 35px;

        &__counter {
         font-size: 20px;
        }

        &__counter-big {
          font-size: 30px;
          line-height: 50px;
        }
      }
    }
  }

  @media screen and (max-width: $sm-point){
    padding-top: 30px;
    .tab {
      &:not(:last-child) {
        margin-right: 20px;
      }

      .main-title {
        font-size: 60px;
        line-height: 65px;
      }

      &-item {
        font-size: 25px;

        &__counter {
          font-size: 15px;
          margin-left: 5px;
        }

        &__counter-big {
          font-size: 25px;
          line-height: 40px;
        }
      }
    }
  }

  @media screen and (max-width: $xs-point){
    flex-wrap: nowrap!important;
    overflow-x: scroll;
    overflow-y: hidden;

    .tab {
      .main-title {
        font-size: 40px;
        line-height: 50px;
      }
      &-item {
        &__counter-big {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
}