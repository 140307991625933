.hero-section__author {
  justify-content: space-between;
  .hero-section__left {
    .author-info {
      margin-top: 30px;
      p {
        font-size: 18px;
        line-height: 25px;
        color: $dark-gray;
      }
    }
  }

  .hero-section__right {
    margin-left: 0!important;
    display: flex;
    justify-content: flex-end;
    padding-left: 20px;
  }

  .photo {
    background: $lighter-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    &-block {
      padding: 40px;
      @media screen and (max-width: $md-point){
        padding: 20px;
      }
    }
  }


  .big-lettering {
    font-size: 100px;
    line-height: 100px;
  }

  @media screen and (max-width: $lg-point){
    .hero-section__left {
      .author-info {
        margin-top: 20px;
      }
    }
  }

  @media screen and (max-width: $md-point){
    .hero-section__right {
      justify-content: flex-start;
      padding-left: 0;
    }
  }

  @media screen and (max-width: $xs-point){
    .big-lettering {
      font-size: 88px;
      line-height: 90px;
    }
  }
}

.hero-section {
  display: flex;
  align-items: center;

  position: relative;
  z-index: 5;
  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 480px;

    &_bottom {
      display: flex;
      align-items: center;
      cursor: pointer;

      p {
        text-transform: uppercase;
        color: rgba(0,0,0,0.5);
        font-size: 13px;
        font-weight: 700;
        letter-spacing: .3rem;
      }

      img {
        animation: mouse 1s linear infinite;
      }
    }
  }

  &__right {
    position: relative;
    margin-left: 40px;
  }

  @media screen and (max-width: $lg-point){
    $coef: 700/600;
    &__left, &__right {
      height: 480px/$coef;
    }

    &__right {
      margin-left: 0;
      padding-left: 40px;
    }
  }

  @media (max-width: $md-point) {
    height: auto;
    &__left {
      height: auto;
      margin-bottom: 60px;
    }

    &__right {
      height: auto;
      padding-left: 0;
    }

    .scroll-down {display: none}
  }

  @media (max-width: $sm-point) {
    min-height: 480px;
    height: 100%;
    &__left {
      margin-bottom: 40px;
    }
  }

}

@keyframes mouse {
  50% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(0%);
  }
}

.hero-bg {
  background-size: cover;
  position: relative;
  height: auto;
  min-height: calc(600px + 160px);


  &__white {
    background-color: #F8F7FF;
    height: 100vh;
  }
  
  &__author {
    background-image: url(../assets/author-bg.jpg);
    height: 100vh;

    &::before {
      content:'';
      background-color: $dark-gray;
      background-repeat: repeat-y;
      opacity: .5;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;bottom: 0;
      right: 0;left: 0;
    }
  }

  @media (max-width: $md-point) {
    height: auto;
  }
}

.main-block__slider {
  background-image: url(../assets/mainPhotoPeople.png);
  background-size: cover;
  background-repeat: no-repeat;
  h1 {
    color: $white;
  }
}
