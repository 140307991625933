$top-pad: 97px;

.footer {
  background-color: $blue;
  background-repeat: no-repeat;
  overflow: hidden;

  .row {
    margin-left: -30px;
    margin-right: -30px;
  }

  div[class^="col"] {
    padding-left: 30px;
    padding-right: 30px;
  }

  &-bg {
    padding: 86px 0 85px;

    ul {
      position: relative;
    }
  }

  &-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 86px;
    padding-bottom: $top-pad;
    background-color: $blue;

    .row:nth-child(2) {
      align-items: center;
    }
  }

  &-icons {
    display: flex;
    justify-content: space-between;

    a {
      width: 50px;
      height: 50px;
      border: 2px solid #EBF3F1;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      &:hover {
        color: $red;
        border: 2px solid $red;

      }
    }
  }

  &-form {
    height: 50px;
    display: flex;

    input {
      border: 1px solid rgba(#ffffff, .1);
      border-radius: 2px 0 0 2px;
      height: 100%;
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      &::placeholder {
        color: #ffffff;
      }
    }
  }

  @media screen and (max-width: $lg-point) {
    &-icons {
      a {
        width: 40px;
        height: 40px;
      }
    }

  }

  @media (max-width: $md-point) {
    $top-pad: 50px;
    .list-links__white {
      display: inline-block;
      //text-align: center;
    }

    &-right {
      padding-top: $top-pad;
      padding-bottom: $top-pad;
      padding-left: 70px!important;
      padding-right: 70px!important;

      .row:nth-child(2) {
        //justify-content: center;
        margin-top: 36px;
      }

      .footer-icons {
        margin-bottom: 40px;
      }
    }

    &-bg {
      padding: $top-pad 0 $top-pad;

      &::before {
        //TODO js height bg image count
        $coef: 554/460;
        height: 554px;
        width: 370px * $coef;

        transform: translateX(-30%);
      }
    }

    &-form {
      input {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  @media (max-width: $sm-point) {

    div[class^="col"] {
      padding-left: 30px!important;
      padding-right: 30px!important;
    }

  }

  @media (max-width: $xxs-point) {
    $coef: 500/ 370;

    .footer-bg, .footer-right {
      max-width: 100%;
      flex: 0 0 100%;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .footer-bg::before {
      //TODO js height bg image count
      width: 500px;
      height: 460px*$coef;

      transform: translate(3%,-20%);
    }

    div[class^="col"] {
      text-align: center;
    }

    .footer-right {
      .row:nth-child(2) {
        justify-content: center;
      }

      .footer-icons {
        order: 2;
        margin-top: 40px;
        justify-content: center;

        a:not(:last-child){
          margin-right: 20px;
        }
      }
    }
  }
}
