

.errorPage {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 160px;
  padding-top: 100px;
  @media screen and (max-width: $md-point) {
    padding-bottom: 70px;
  }
  img {
    display: inline-block;
    max-width: 100%;
  }
  &__title {
    font-weight: bold;
    font-size: 27px;
    line-height: px;
    text-align: center;
    color: rgba(#000000, .75);
    font-family: 'Lato', sans-serif;
    margin-top: 14px;
    margin-bottom: 30px;
  }
  &__btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    max-width: 245px;
    width: 100%;
    margin: 0 auto;
    background: #00A8E2;
    border-radius: 2px;
    letter-spacing: 2px;
    &:hover {
      color: $red;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #2F343B;
    margin-top: 20px;
    font-family: 'Lato', sans-serif;
  }
  .bottom-header {
    max-width: 560px;
    width: 100%;
    margin: 22px auto 0;
  }
}

