.quote {
  //max-width: 500px;
  width: 100%;
  margin-top: 50px;

  padding-bottom: 22px;
  border-bottom: $light-gray 1px solid;
  

  p {
    color: $dark-gray;
  }

  &__meta {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__center {
    margin-top: 10px;
    p {
      font-size: 27px;
      line-height: 35px;
      font-weight: bold;
      //cursor: pointer;
    }

    .big-text {
      font-size: 40px;
      line-height: 60px;
    }
  }

  &__bottom {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;

    .quote__author {
      margin-top: 0;
    }

    [class^="icon-"]::before, [class*=" icon-"]::before {
      color: $gray;
    }

    .icons {
      display: flex;
      align-items: center;
      .counter {
        color: $dark-gray;
      }

      span:not(:last-child) {
        margin-right: 30px;
      }

      span {
        cursor: pointer;
      }

      .icon-comment {
        margin-top: 2px;
      }

      .icon-comment::before {
        font-size: 18px;
      }

      .icon-share::before {
        font-size: 20px;
      }

      .icon-heart::before {
        font-size: 17px;
      }
    }
  }

  &__meta {
    p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 6px;
      span {
        color: $blue!important;
      }
    }
  }
  &__author {
    margin-top: 18px;
    a {
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      cursor: pointer;
      color: $dark-gray;
      transition: all .3s ease;
  
      &:hover {
        opacity: .6;
        color: $dark-gray;
      }
    }
    
  }

  @media screen and (max-width: $sm-point){
    margin-top: 40px;

    &__center {
      p {
        font-size: 22px;
        line-height: 30px;
      }

      .big-text {
        font-size: 26px;
        line-height: 35px;
      }
    }
  }
}

.tab-content {
  .col-lg-6 {
    &:first-child {
      .quote {
        margin-top: 0;
      }
    }
    &:nth-child(2){
      .quote {
        margin-top: 0;
      }
    }
  
  }
  @media (max-width: $md-point){
    .col-lg-6 {
      &:nth-child(2){
        .quote {
          margin-top: 80px;
        }
      }
    }
  }
  @media (max-width: $md-point){
    .banner-container-300 {
      margin-top: 40px !important;
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: $sm-point){
    .col-lg-6 {
      &:nth-child(2){
        .quote {
          margin-top: 40px;
        }
      }
    }
  }
}

