.slick-active {
  position: relative;
}

/* Dots */
.slick-dotted.slick-slider
{
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;

  display: block;


  padding: 0;
  margin: 0;

  list-style: none;
  text-align: center;

  @media screen and (max-width: $md-point) {
    width: 720px;
  }

}

.slick-dots li
{
  position: relative;

  display: inline-block;

  width: 10px;
  height: 10px;
  margin: 15px 5px 0;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button
{
  font-size: 0;
  line-height: 0;

  display: block;

  width: 10px;
  height: 10px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
  opacity: .5;
}
.slick-dots li button:before
{
  position: absolute;
  top: 0;
  left: 0;

  width: 10px;
  height: 10px;

  content: '';
  opacity: .2;
  border-radius: 50%;
  background-color: $blue;
}
.slick-dots li.slick-active button:before
{
  opacity: .5;
  color: black;
}

.icon-arrow-right:before {
  position: absolute;
  top:50%;left: 50%;
}


