input, textarea {
  //padding-left: 30px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: $dark-gray;
  font-weight: 600;
  position: relative;

  &::placeholder {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: rgba(#000000, .3);
    font-weight: 600;
  }

  @media screen and (max-width: 1199px) {
    font-size: 16px;
    &::placeholder {
      font-size: 14px;
    }
  }
}

button {
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

button:focus {
  //outline: 1px solid #fff;
  //outline-offset: -4px;
  outline: none;
}

button:active {
  transform: scale(0.99);
}

textarea {
  display: block;
  border: none;
  resize: none;
  overflow: hidden;
  min-height: 60px;
  max-height: 600px!important;
}

.gray-form__elem {
  background-color: $lighter-gray;
  border: 2px solid transparent;
  border-radius: 2px;
  padding: 18px 30px;
  width: 100%!important;
  transition: background .3s ease-in-out;
  line-height: 20px;

  &:active, &:focus {
    background-color: #fff;
    border-left: 2px solid $blue;
  }

  &:disabled {
    opacity: .5;
  }
  &.error {
    border: 2px solid $pink;
  }

  &::placeholder {
    color: rgba(#000000, .3);
  }

  @media screen and (max-width: $lg-point){
    padding-left: 20px;
    padding-right: 20px;
  }

  /*@media screen and (max-width: $xs-point){*/
  /*  padding-top: 15px;*/
  /*  padding-bottom: 15px;*/
  /*}*/
}

.add-form {
  &__elem {
    //display: block;
    margin-top: 30px;
  }

  .input-group {
    label:last-child {
      margin-left: 60px;
    }
  }

  @media screen and (max-width: $lg-point){
    .input-group {
      label:last-child {
        margin-left: 30px;
      }
    }
  }

  @media screen and (max-width: $sm-point){
    &__elem {
      margin-top: 0;

      * {
        margin-bottom: 10px;
      }

    }

    .input-group {
      flex-wrap: wrap;
      * {
        width: 100%;
        margin-left: 0!important;
      }
    }
  }

}

.input-group {
  width: 100%;
  display: flex;

  label {
    width: 50%;
  }

  @media screen and (max-width: $xs-point){
    flex-wrap: wrap;
    * {
      width: 100%;
      margin-left: 0!important;
    }
  }
}

.comment-form__container {
  .submit-input {
    margin-top: 20px;
    width: 100%;
    position: relative;

    textarea {
      padding-right: 60px;
    }

    .send-icon {
      position: absolute;
      bottom: calc(30px - 18px / 2);
      right: 30px;

      transition: all .3s ease;
      width: 14px;
      height: 18px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("../assets/icons/send.svg");

      &:hover {
        transform: rotate(10deg);
      }
    }
  }

  @media screen and (max-width: $lg-point){
    .submit-input {

      textarea {
        padding-right: 30px;
      }

      .send-icon {
        right: 15px;
      }
    }
  }

  @media screen and (max-width: $md-point){
    .submit-input {
      margin-top: 1.5rem;
    }
  }

  @media screen and (max-width: $xs-point){

    input, textarea {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    textarea {
      min-height: 50px;
      max-height: 500px!important;
    }

    .submit-input {
      .send-icon {
        bottom: calc(25px - 18px / 2);
      }
    }

  }
}

.search-form {
  width: 100%;
  position: relative;

  input {
    width: 100%;
    border-bottom: 2px solid $tab-gray;
    padding: 0 0 15px;
    font-family: "Poppins", sans-serif;
    font-size: 65px;
    line-height: 75px;
    font-weight: 700;
    color: $dark-gray;
    letter-spacing: 0;
    padding-right: 45px;
  }

  .icon-search {
    font-size: 35px;
    position: absolute;

    top:64%;
    right: 0;

    transform: translate(0, -50%);
  }

  @media screen and (max-width: $xs-point){
    input {
      font-size: 45px;
      line-height: 45px;
      padding: 0 0 10px;
      padding-right: 30px;
    }

    .icon-search {
      font-size: 21px;
      transform: translate(0, 25%);
    }

  }
}
/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  color: $dark-gray;
  opacity: .5;

  &:hover .checkmark {
    border: 6px solid $blue;
  }
}

.terms{
  display: none;
  &:checked ~ label{
    opacity: 1;
    .checkmark{
      border: 6px solid $blue;
    }
  }
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0%;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid $light-gray;
  transform: translateY(25%);
  transition: all .3s ease-in;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.message {
  font-size: 14px;
  color: red;

  &-top {
    position: absolute;
    top: -25px;
  }

  &-bottom {
    position: absolute;
    bottom: -25px;
  }

  @media screen and (max-width: $md-point){
    &-top {
      top: -20px;

    }

    &-bottom {
      bottom: -20px;
    }
  }
}

.capcha-container {
  display: flex;

  .refresh-btn {
    margin-left: 20px;
  }

  .capcha {
    width: 240px;
    height: 60px;
  }

  @media screen and (max-width: 380px){

    .refresh-btn {
      margin-left: 5px;
    }
  }

}

