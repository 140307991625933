.nav-chain {
  ul {
    display: flex;
    align-items: center;

    .active {
      a {
        color: $dark-gray;
      }
    }

    .separator {
      display: flex;
      justify-content: space-between;
      width: 15px;
      height: 3px;
      @media screen and (max-width: 374px) {
        margin-top: 3px;
      }
      span {
        background-color: $gray;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        @media screen and (max-width: 374px) {
          width: 2px;
          height: 2px;
        }
      }

      span:not(:last-child) {
        margin-right: 3px;
      }
    }

    li {
      &:not(:last-child) {
        padding-right: 40px;
        margin-right: 20px;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          right: 0;
          width: 20px;
          height: 1px;
          top: 49%;
          background: $gray;
        }
      }

      a {
        font-size: 14px;
        color: $gray;
        font-weight: 600;
        line-height: 20px;
        
        @media screen and (max-width: 374px) {
          font-size: 12px;
        }

      }

    }

  }

  @media screen and (max-width: 420px){
    overflow-x: scroll;
    ul {
      li {
        &:not(:last-child) {
          margin-right: 15px;
          @media screen and (max-width: 374px) {
            margin-right: 10px;
          }
        }

        a{
          white-space: nowrap;
        }
      }
    }
  }
}