$lighter-gray: rgba(#E8F4F7, .6);
$banner-gray: rgba(#E8F4F6, .6);
$tab-gray: #DEE3E9;
$light-gray: #D7DEE7;
$gray: rgba(#000000, .5);
$dark-gray: rgba(#000000, .75);
$violet: #9381FF;
$white: #ffffff;

$darker-blue: #1B2731;
$dark-blue: #212F3C;
$light-blue: #D9E9FF;

$violet: #9381FF;
$green: #19863F;
$blue: #00A8E2;
$red: #DE0F1D;
$pink: #E96F8C;
$orange-light: #FFEEDD;

.blue-gradient{
  background-image: linear-gradient(-140deg, #40B9F2 0%, #4091F2 100%);
}

.purple-gradient{
  background-image: linear-gradient(39deg, #4D42CF 0%, #4091F2 100%);
}

.pink-gradient{
  background-image: linear-gradient(33deg, #D848B3 0%, #F27786 100%);
}

.gray-gradient {
  background: linear-gradient(180deg, #E5F2F4 0%, rgba(229, 242, 244, 0) 100%);
  background-size: 100vw 90px;
  background-repeat: no-repeat;
}

.pink {
  background: #FF8181;
}
.orange {
  background: #FFC7A1;
}
.violet {
  background: $blue;
}

.green {
  background: $green;
}

.blue {
  background: $blue;
}

.red {
  background: $red;
}
