.container-latest {
  [class^="col-"]:last-child {
    padding-left: 55px;
  }
  [class^="col-"]:first-child {
    padding-right: 55px;
  }

  .col-lg-6:nth-child(odd) {
    padding-right: 55px;
  }
  .col-lg-6:nth-child(even) {
    padding-left: 55px;
  }

  h2 {
    margin-bottom: 100px;
  }

  @media (max-width: $lg-point){
    [class^="col-"]:last-child {
      padding-left: 30px;
    }
    [class^="col-"]:first-child {
      padding-right: 30px;
    }
    .col-lg-6:nth-child(odd) {
      padding-right: 30px;
    }
    .col-lg-6:nth-child(even) {
      padding-left: 30px;
    }
  }

  @media (max-width: $md-point){
    [class^="col-"]:last-child {
      padding-left: 0;

      .quote:first-child {
        margin-top: 40px!important;
      }
    }
    [class^="col-"]:first-child {
      padding-right: 0;
    }
    .col-lg-6:nth-child(odd) {
      padding-right: 0px;
    }
    .col-lg-6:nth-child(even) {
      padding-left: 0px;
    }

  }
}