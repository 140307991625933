.idioms-page {
  .main-title {
    max-width: 760px;
    width: 100%;
  }
  .sec-comments {
    border-top: 1px solid rgba(#000, .1);
    .comments-container {
      max-width: 1000px;
      padding-right: 15px;
      padding-left: 15px;
      width: 100%;
      margin: 0 auto;
    }
  }
  .signUp {
    background-image: url(../assets/signBg.png);
    background-size: cover;
    background-repeat: no-repeat;
    &__quote {
      &-text {
        font-weight: 500;
        font-size: 40px;
        line-height: 50px;
        color: #FFFFFF;
        margin-top: 20px;
        padding-bottom: 40px;
        @media screen and (max-width: $md-point){
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
    .author {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #FFFFFF;
    }
  }
  .blockLeft { 
    max-width: 700px;
    width: 100%;
    padding: 60px 55px 60px;
    @media screen and (max-width: $md-point){
      padding: 30px 30px 30px;
    }

  }
  .blockRight {
    max-width: 490px;
    width: 100%;
    margin-left: 20px;
    text-align: left;
    padding: 0 100px;
    a {
      display: flex;
      align-items: center;
      max-width: 200px;
      width: 100%;
      .icon-fb {
        margin-right: 20px;
        font-size: 20px;
      }
    }
    @media screen and (max-width: 1440px){
      max-width: 290px;
      width: 100%;
      margin-left: 20px;
      padding: 0;
      text-align: left;
    }
    @media screen and (max-width: $md-point){
      text-align: center;
      margin-top: 20px;
      max-width: 200px;
      a {
        margin: 0 auto;
      }
    }

  }
  .blockRightTitle {
    margin-bottom: 30px;
  }
  .signUpContent {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $md-point){
      flex-direction: column;
    }

  }
  
}