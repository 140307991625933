.card {
  position: relative;
  &__text {
     overflow: hidden;
     p {
      color: #fff;
      font-weight: bold;
    }
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__author {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      opacity: .6;
      color: #fff;
    }
  }

  &__icons {
    display: flex;
    align-items: center;
    .counter {color: #fff;}
    span{cursor: pointer;}

    [class^="icon-"]::before, [class*=" icon-"]::before {color: #fff;}

    .icon-share::before {font-size: 20px;}
    .icon-comment {margin-top: 2px;}
    .icon-comment::before {font-size: 18px;}

    span:not(:last-child) {
      margin-right: 30px;
    }
  }

  @media screen and (max-width: $sm-point){
    &__author {font-size: 16px;}

    span:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.image-card {
  &__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;left: 0;
    z-index: 0;
  }

  .card__content {
    position: relative;
    z-index: 5;
  }
}


