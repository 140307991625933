.popular {
  &__title {
    h4 {
      text-align: center;
    }
  }
  &__content {
    .item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
      padding: 15px 20px;
      @media screen and (max-width: $md-point){
        padding: 10px 15px;
      }

      &:hover {
        background: $red;
        .name {
          color: $white;
        }
      }
    }
    .img {
      max-width: 60px;
      width: 100%;
      margin-right: 20px;
      @media screen and (max-width: $md-point){
        max-width: 40px;
      }

    }
    .name {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.75);
    }
  }
  .tabs-container {
    padding-top: 0;
    .tab-item{
      font-weight: 600;
      font-size: 18px !important;
      line-height: 70px;
    }
  }
  .list-links__gray {
    margin-top: 44px;
  }
  .container-offset-20>[class^="col"] {
    padding: 0;
  }
}