.interview {
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 100px;

  [class^="col"] {
    padding-right: 20px;
    padding-left: 20px;
  }

  &-cover {
    max-width: 200px!important;
  }

  &-meta {
    margin-top: 24px;

    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
    color: $gray;

    span {
      color: $dark-gray;
    }
  }

  .title {
    margin-bottom: 14px;
  }

  .circle-cover {
    width: 160px;
    height: 160px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }

  @media screen and (max-width: $lg-point){
    margin-left: -15px;
    margin-right: -15px;

    [class^="col"] {
      padding-left: 15px;
      padding-right: 15px;
    }

    &-cover {
      max-width: 180px!important;
    }
  }

  @media screen and (max-width: $md-point){
    margin-top: 60px;
  }

  @media screen and (max-width: $sm-point){
    justify-content: center;
    &-cover {
      max-width: 130px!important;
    }

    .circle-cover {
      width: 100px;
      height: 100px;
    }

    .title {
      font-size: 24px;
      line-height: 35px;
      margin-bottom: 20px;
    }

    [class^="col"]:last-child {
      max-width: 365px;
      margin-left: -130px;
    }

    &-title {
      margin-left: 130px;
    }

    &-meta {
      margin-top: 14px;
    }
  }

}