//TODO absolute alphabet

.alphabet-active {
  color: $dark-gray!important;
  &:hover {
    .icon-alphabet, .text__small {
      color: $gray!important;
    }
  }
}

.bottom-header__white {
  background-color: rgba(255,255,255,0.1)!important;

  .bottom-header__left {
    display: flex;

    &:hover {
      & .icon-alphabet, & .text__small {
        opacity: .5;
      }
    }

    .icon-alphabet, .text__small {
      color: #fff!important;
    }
  }

  .bottom-header__right {
  }

  .search-field {
    input {
      color: rgba(#000000, .3)!important;
      &::placeholder {
        color: rgba(#000000, .3);
      }
    }

    button {
      color: #fff!important;

      &:hover {
        opacity: .5;
      }
    }
  }

  .bottom-header__container {

    .alphabet {
      li {
        &:hover {
          a {
            opacity: .5;
          }

        }
      }

      a {
        color: #fff !important;
      }
    }

    .icon-delete {
      color: #fff !important;
      &:hover {
        opacity: .5;
      }
    }
  }

}

.bottom-header__active {
  position: relative;

  &:after, &:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(244, 246, 249, 0);
    border-right-color: #F4F6F9;
    border-width: 7px;
    margin-top: -7px;
  }

  &:before {
    border-color: rgba(215, 222, 231, 0);
    border-right-color: #D7DEE7;
    border-width: 9px;
    margin-top: -9px;
  }
}

.bottom-header__active__white {
  position: relative;

  &:after, &:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    margin-top: -7px;
  }

  &:before {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: rgba(255,255,255,0);
    border-width: 9px;
    margin-top: -9px;
  }
}

.bottom-header {
  background-color:$lighter-gray;
  min-height: 60px;
  padding: 0;
  padding-top: 100px;
  &__left {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 15px 40px;
    max-width: 250px!important;

    .icon-alphabet {
      font-size: 28px;
      color: $gray;
      margin-right: 20px;
      transition: all .3s ease;
    }

    .text__small {
      transition: all .3s ease;
    }

    &:hover {
      .icon-alphabet, .text__small {
        color: $dark-gray;
      }
    }
  }

  &__right {
    padding: 0 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }


  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
  }

  .search-field {
    input {
      width: 90%;
      height: 100%;
      position: relative;
      &::placeholder {
        color: rgba(#000000, .3)
      }
    }

    button {
      font-size: 17px;
      color: rgba(#2F343B, .4);

      &:hover {
        color: $blue;
      }
    }
  }

  .alphabet-container {
    padding-top: 0!important;
    padding-bottom: 0!important;
  }

  &__container {
    width: 100%;
    min-width: 100%;
    transition: opacity .3s ease;
    padding: 19px 0 21px;

    .alphabet {
      display: flex;

      li {
        transition: all .1s ease;

        &:hover {
          a {
            color: $blue;
          }

        }

        &:not(:last-child) {
          margin-right: 15px;
        }
      }

      a {
        text-transform: uppercase;
        font-size: 13px;
        color: $gray;
        font-weight: 700;
        text-decoration: none;
      }
    }

    .icon-delete {
      cursor: pointer;
      color: $dark-gray!important;
      font-size: 12px;
      padding-top: 2px;
      transition: all .3s ease;
    }

    @media screen and (max-width: $md-point){
      .alphabet {
        flex-wrap: wrap;
        a {
          font-size: 13px;
          line-height: 26px;
        }
      }
    }
  }

  @media screen and (max-width: $lg-point){
    &__left, &__right {
      padding-left: 15px!important;
      padding-right: 15px!important;
    }
  }

  @media screen and (max-width: $sm-point){

    &__right, &__left  {
      padding-left: 10px!important;
      padding-right: 10px!important;
    }

    &__left {
      justify-content: center;
      max-width: 38px!important;
      p {
        font-size: 0;
        display: none;
      }
      .icon-alphabet {
        font-size: 22px;
        margin-right: 0;
      }
    }

  }

  @media screen and (max-width: $xs-point){
    &__left {
      .icon-alphabet {
        font-size: 18px;
      }
    }

    .search-field {
      button {
        font-size: 16px;
        color: $gray;
      }
    }
  }
}

.hide-header {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: inherit;
  z-index: -1;
}

