html, body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;

}

* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

input, button {
  background-color: unset;
  border: none;
}

button {
  cursor: pointer;
}